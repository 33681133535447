import axios from "axios";
import { IS_VENDOR_LOGGEDIN } from "constant";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_V2_BASE_URL,
  timeout: 30 * 1000,
});

Axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorMessage = error.message || error.response?.data?.message;
    const statusCode = error.response?.status;

    if (
      statusCode === 408 ||
      errorMessage?.toLowerCase() === "call cancelled"
    ) {
      return Promise.reject(new Error("An error occurred. Try again."));
    }

    if (errorMessage && errorMessage.includes("Rejected")) {
      return Promise.resolve();
    }

    // Handle 401 errors
    if (errorMessage.includes("401")) {
      localStorage.removeItem("token");
      localStorage.removeItem("persist:root");
      localStorage.removeItem("storeUserRole");
      localStorage.removeItem("user");
      localStorage.removeItem(IS_VENDOR_LOGGEDIN);
      window.location.replace("/auth/login");
    }

    if (errorMessage.includes("403")) {
      localStorage.removeItem("token");
      localStorage.removeItem("storeUserRole");
      localStorage.removeItem("user");
      localStorage.removeItem("persist:root");
      localStorage.removeItem(IS_VENDOR_LOGGEDIN);
      window.location.replace("/auth/login");
    }

    return Promise.reject(error);
  }
);

export default Axios;
