import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth, Account } from "services/network";
import { Auth as AuthV2 } from "services/network-v2";
import { Account as AccountV2 } from "services/network-v2";

const initialState = {
  loginLoading: false,
  loginError: null,
  data: {},
};

export const requestEmailVerificationRequest = createAsyncThunk(
  "account/signUp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.requestVerificationEmail(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const signUpRequest = createAsyncThunk(
  "account/signUp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.signUp(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginRequest = createAsyncThunk(
  "account/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.login(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyEmailRequest = createAsyncThunk(
  "account/verifyEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.verifyEmail(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const loginSocialAuthRequest = createAsyncThunk(
  "account/loginSocialAuth",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.loginSocialAuth(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const socialLoginRequest = createAsyncThunk(
  "account/socialLogin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Auth.socialLogin(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPasswordRequest = createAsyncThunk(
  "account/forgotPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.forgotPassword(payload);
      return response.data.detail;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "account/resetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.resetPassword(payload);
      return response.data.detail;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserRequest = createAsyncThunk(
  "account/getUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.getUser(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePasswordRequest = createAsyncThunk(
  "account/changePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthV2.changePassword(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getConfigRequest = createAsyncThunk(
  "account/getConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.getConfig();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateConfigRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.updateConfig(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAttendeeRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.getAttendee();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAttendeeRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.createAttendee(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAttendeeRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.deleteAttendee(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getContactsRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.getContact();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createContactRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.createContact(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContactRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.deleteContact(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPromotersRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.getPromoter();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createPromoterRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.createPromoter(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePromoterRequest = createAsyncThunk(
  "account/updateConfig",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.deletePromoter(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const ChangeAccountPasswordRequest = createAsyncThunk(
  "account/changePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.changeAccountPassword(payload);
      return response.data.detail;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFlutterWaveBanksRequest = createAsyncThunk(
  "fuel/getFlutterWaveBanks",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.getFlutterWaveBanks();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const closeAccountRequest = createAsyncThunk(
  "fuel/closeAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Account.closeAccount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem("storeUserRole");
      localStorage.removeItem("user");
      state.data = initialState.data;
    },

    setUserData: (state, action) => {
      state.data.user = action.payload;
    },
  },

  extraReducers: {
    [signUpRequest.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [loginRequest.fulfilled]: (state, action) => {
      state.data.user = action.payload.data;
    },
  },
});

export const { setUserData, logoutUser } = accountSlice.actions;
export default accountSlice.reducer;
