import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";

const CreatedAxios = axios.create({
  baseURL: process.env.REACT_APP_API_V2_BASE_URL,
  timeout: 30 * 1000,
});

const Axios = applyCaseMiddleware(CreatedAxios);

Axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      // config.headers.Authorization = `Token ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,

  async (error) => {
    // Suppress errors with "rejected" in the message
    if (error.message.includes("rejected")) {
      console.warn("Request rejected, suppressing the error:", error.message);
      return Promise.resolve(); // Resolve the Promise to suppress the error
    }

    // Handle 401 errors
    if (error.message.includes("401")) {
      // Example actions for 401
      // localStorage.removeItem("token");
      // localStorage.removeItem("persist:root");
      // localStorage.removeItem("storeUserRole");
      // localStorage.removeItem("user");
      // window.location.replace("/auth/login");
    }

    // Handle 403 errors
    if (error.message.includes("403")) {
      // Example actions for 403
      // localStorage.removeItem("token");
      // localStorage.removeItem("storeUserRole");
      // localStorage.removeItem("user");
      // localStorage.removeItem("persist:root");
      // window.location.replace("/auth/login");
    }

    // For other errors, reject as usual
    return Promise.reject(error);
  }
);

export default Axios;
