export const DEFAULTMODALCONFIG = {
  root: "tw-max-w-[600px] tw-m-auto",
  modal: "tw-w-full tw-max-h-full",
};

export const DEFAULTMODALSTYLE = {
  root: "tw-max-w-[500px]   tw-m-auto",
  modal: "tw-w-full  tw-max-h-full",
};

export const FUELSTATIONPLAINCONTAINER = `tw-w-[90%]  tw-py-[2em]
  tw-mx-auto  tw-px-[1.5em] tw-w-full tw-mt-8`;

export const TABLE_BODY_STYLE_CONSTANT = `tw-text-gray-600 [&>*:nth-child(even)]:tw-bg-gray-50 [&>*:nth-child(odd)]:tw-bg-gray-100`;

export const IS_NOT_VERIFIED = "IS_NOT_VERIFIED";
export const IS_VENDOR_LOGGEDIN = "vendorLoggedIn";

export const DISPLAY_VENDORS_DATA =
  localStorage.getItem(IS_VENDOR_LOGGEDIN) === "true" ? true : false;
