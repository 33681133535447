import Loader from "../components/shared/Loader";
import ScrollToTop from "../components/shared/ScrollToTop";
import AppContainer from "./Container";
import EventForm from "components/pages/authenticated-new/pages/Events/pages/EventForm";
import DashboardNotVerified from "components/pages/authenticated-new/pages/NotVerified";
import ActivateWalletPage from "components/pages/authenticated-new/pages/Wallets/UserWallet/ActivateWallet";
import AllRestaurantsPage from "components/pages/guest/AllRestaurants";
import EventDashWithEvent from "components/pages/guest/EventDashWithEvent";
import EventDashTest from "components/pages/guest/EventDashboardTest";
import ForgotPassword from "components/pages/guest/ForgotPassword";
import Login from "components/pages/guest/Login";
import ResetPassword from "components/pages/guest/ResetPasswordPage";
import SingleRestaurants from "components/pages/guest/SingleRestaurant";
import SuccessfullySignedUp from "components/pages/guest/SuccessfulSignup";
import VerifyEmail from "components/pages/guest/VerifyEmail";
import OfflinePage from "components/pages/guest/_OfflinePage";
import { FuelStationRoutes } from "components/routes/FuelStationRoutes";
import PrivateRoute from "components/shared/PrivateRoute";
import GetCoordinates from "components/shared/etc/GetCoordinates";
import React, { useLayoutEffect, lazy, Suspense, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { CompatRouter, CompatRoute } from "react-router-dom-v5-compat";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { fetchAllEventCategoriesV2Request } from "state/slices/events";
import store, { persistor } from "state/store";

const EventListPage = lazy(() =>
  import("components/pages/guest/EventListPage")
);

const CategoriesPage = lazy(() =>
  import("components/pages/guest/CategoryListPage")
);

const BookingFlowRoutes = lazy(() =>
  import("../components/routes/BookingFlowRoutes")
);

const DashboardRoutes = lazy(() => import("components/routes/DashboardRoutes"));

const PrintTicket = lazy(() =>
  import(
    "components/pages/authenticated/user-dashboard/pages/tickets/print-ticket"
  )
);
const EditEventPage = lazy(() =>
  import("components/pages/authenticated/Event-dashboard/pages/editEventPage")
);
const CreateEventPage = lazy(() =>
  import("components/pages/authenticated/Event-dashboard/pages/createEventPage")
);
const SearchByNameResultPage = lazy(() =>
  import("../components/pages/guest/SearchByNameResultPage")
);
const SearchByDateResultPage = lazy(() =>
  import("../components/pages/guest/SearchByDateResultPage")
);

const BookingErrorReport = lazy(() =>
  import("components/pages/guest/BookingReport/error")
);
const BookingSuccessReport = lazy(() =>
  import("components/pages/guest/BookingReport/success")
);
const PrivacyPolicy = lazy(() => import("Static-pages/privacy-policy"));
const Terms = lazy(() => import("Static-pages/Terms"));
const AboutUs = lazy(() => import("Static-pages/About-us"));
const Faq = lazy(() => import("Static-pages/Faq"));
const HowEventWorks = lazy(() => import("Static-pages/How-event-works"));
const HowStoreWorks = lazy(() => import("Static-pages/How-store-works"));
const HowFuelWorks = lazy(() => import("Static-pages/How-fuel-works"));
const ContactUs = lazy(() => import("Static-pages/Contact-us"));
const PrintOrders = lazy(() =>
  import(
    "components/pages/authenticated/Store-dasboard/pages/Orders/print-orders"
  )
);
const PrintWallet = lazy(() =>
  import(
    "components/pages/authenticated/user-dashboard/pages/User-wallet/print-wallet"
  )
);
const CustomerStoreRoutes = lazy(() =>
  import("components/routes/CustomerStoreRoutes")
);
const HomePage = lazy(() => import("components/pages/guest/HomePage"));

const App = () => {
  function onBeforeLift() {
    store.dispatch;
  }

  useEffect(() => {
    store.dispatch(fetchAllEventCategoriesV2Request({}));
  }, []);

  useLayoutEffect(() => {
    document.title =
      "Buy, Sell, Re-sell Event Tickets, No Booking Fee & No Hidden Charges - Triple7events";

    document.body.classList.add("tw-m-auto");
  }, []);

  //now check and redirect to
  // verification screen if email is not verified

  return (
    <>
      <StoreProvider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          <BrowserRouter>
            <CompatRouter>
              {/* <GetCoordinates setGettingPosition={setGettingPosition} /> */}
              <ScrollToTop />
              {/* {gettingPosition
              ? (
                  <div className="d-flex justify-content-center align-items-center h-vh">
                  <Loader />
                  </div>
              )
            : ( */}
              <AppContainer>
                <Suspense
                  fallback={
                    <div className="d-flex justify-content-center align-items-center h-vh">
                      <Loader />
                    </div>
                  }
                >
                  <Switch>
                    <CompatRoute
                      exact
                      path="/ticket/success/:ticketId/:reserveId"
                      component={BookingSuccessReport}
                    />
                    <CompatRoute
                      exact
                      path="/ticket/error/:ticketId/:reserveId"
                      component={BookingErrorReport}
                    />
                    <CompatRoute path="/offline" component={OfflinePage} />
                    <CompatRoute exact path="/auth/login" component={Login} />
                    <CompatRoute exact path="/auth/signup" component={Login} />
                    <CompatRoute
                      exact
                      path="/auth/verify-email"
                      component={VerifyEmail}
                    />
                    <CompatRoute
                      exact
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                    />
                    <CompatRoute
                      exact
                      path="/auth/reset-password"
                      component={ResetPassword}
                    />
                    <CompatRoute exact path="/" component={HomePage} />
                    <CompatRoute
                      exact
                      path="/search-by-name"
                      component={SearchByNameResultPage}
                    />
                    <CompatRoute
                      exact
                      path="/search-by-date"
                      component={SearchByDateResultPage}
                    />
                    <CompatRoute
                      exact
                      path="/events"
                      component={EventListPage}
                    />
                    <CompatRoute
                      path="/event/:id/:eventName"
                      component={BookingFlowRoutes}
                    />
                    <CompatRoute
                      exact
                      path="/categories"
                      component={CategoriesPage}
                    />
                    <CompatRoute
                      exact
                      path="/all-restaurants"
                      component={AllRestaurantsPage}
                    />
                    <CompatRoute
                      exact
                      path="/single-restaurants"
                      component={SingleRestaurants}
                    />
                    <CompatRoute
                      exact
                      path="/event-dash-test"
                      component={EventDashTest}
                    />
                    <CompatRoute
                      exact
                      path="/event-dash-event"
                      component={EventDashWithEvent}
                    />
                    <CompatRoute path="/privacy" component={PrivacyPolicy} />
                    <CompatRoute path="/terms" component={Terms} />
                    <CompatRoute path="/about" component={AboutUs} />
                    <CompatRoute
                      path="/auth/success"
                      component={SuccessfullySignedUp}
                    />

                    <CompatRoute
                      path="/account/verify"
                      component={DashboardNotVerified}
                    />

                    <CompatRoute path="/faq" component={Faq} />
                    <CompatRoute path="/contact" component={ContactUs} />
                    <CompatRoute
                      path="/how-event-works"
                      component={HowEventWorks}
                    />
                    <CompatRoute
                      path="/how-store-works"
                      component={HowStoreWorks}
                    />
                    <CompatRoute
                      path="/how-fuel-works"
                      component={HowFuelWorks}
                    />
                    <PrivateRoute
                      exact
                      path={`/print-ticket/:ticketId`}
                      component={PrintTicket}
                    />
                    <PrivateRoute
                      exact
                      path={`/print-orders`}
                      component={PrintOrders}
                    />
                    <PrivateRoute
                      exact
                      path={`/print-wallet`}
                      component={PrintWallet}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard/event/:id/edit"
                      component={EventForm}
                    />

                    <PrivateRoute
                      exact
                      path={`/dashboard/create`}
                      component={EventForm}
                    />
                    <PrivateRoute
                      exact
                      path={`/dashboard/create/old`}
                      component={CreateEventPage}
                    />

                    <PrivateRoute
                      exact
                      path={`/dashboard/resell-ticket`}
                      component={EventForm}
                    />

                    <PrivateRoute
                      path="/dashboard/activate-wallet"
                      component={ActivateWalletPage}
                    />

                    <PrivateRoute
                      path="/dashboard"
                      component={DashboardRoutes}
                    />

                    <Route path="/store" component={CustomerStoreRoutes} />
                    <Redirect to="/auth/login" />
                  </Switch>
                </Suspense>

                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  toastClassName="px-2 shadow-sm rounded-md font-weight-normal"
                />
              </AppContainer>
            </CompatRouter>
          </BrowserRouter>
        </PersistGate>
      </StoreProvider>
    </>
  );
};

export default App;
