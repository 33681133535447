import axios from "../api-v2";

export const requestVerificationEmail = (data) => {
  return axios.post("/auth/request-verification", data);
};

export const signUp = (data) => {
  return axios.post("/auth/signup", data);
};

export const login = (data) => {
  return axios.post("/auth/signin", data);
};

export const verifyEmail = (data) => {
  return axios.post("/auth/verify-email", data);
};

export const loginSocialAuth = (data) => {
  return axios.post("/auth/social-auth", data);
};

export const socialLogin = (data) => {
  return axios.post("/api/v2.0/account/social_auth/", data);
};

export const forgotPassword = (data) => {
  return axios.post("/auth/forgot-password", data);
};

export const changePassword = (data) => {
  return axios.post("/auth/change-password", data);
};

export const resetPassword = (data) => {
  return axios.post("/auth/reset-password", data);
};

export const getUser = (data) => {
  return axios.get("/user/me", data);
};

export const logout = () => {
  return axios.post("/auth/logout/");
};
