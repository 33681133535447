import UnAuthencicatedDashboard from "../dashboard/unautheticatedDashboardLayout";
import { Button } from "components/shared/Button";
import React from "react";
import { Link } from "react-router-dom";

function IndexVerify(props) {
  return (
    <UnAuthencicatedDashboard>
      <div className="tw-mx-auto tw-flex tw-items-center tw-flex-col tw-gap-0">
        <img
          className={"tw-w-[200px]  tw-mb-5 tw-h-[200px]"}
          alt={""}
          src={"/images/auth/sign_up.svg"}
        />
        <h1
          className={" tw-font-[500] tw-text-xl tw-text-center md:tw-text-3xl"}
        >
          Sign Up Successful
        </h1>
        <p className={"text-center  tw-mt-3 tw-text-[#667085] tw-text-base "}>
          We sent you an activation email. Please activate your account to
          continue.
        </p>
        <Link to={"https://mail.google.com/mail"}>
          <Button
            className={"tw-bg-primary-700 tw-py-0 tw-w-full tw-text-white"}
          >
            Check your mail
          </Button>
        </Link>
      </div>
    </UnAuthencicatedDashboard>
  );
}

export default IndexVerify;
