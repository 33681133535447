/* eslint-disable react/react-in-jsx-scope */
import { WALLET_ACCOUNT_TYPES } from "../../constants";
import Hamburger from "../Hamburger";
import "./styles.scss";
import {
  AccountBalanceWalletIcon,
  ChevronRightIcon,
  DashboardIcon,
  EllipseIcon,
  NotificationImportantIcon,
  OutboundIcon,
  PlusIcon,
  PowerIcon,
  SearchIcon,
  SettingsIcon,
  UserIcon,
} from "assets/icons";
import {
  CreateEventIllustration,
  Logo,
  ResellTicketIllustration,
} from "assets/images";
import { Button } from "components/shared/Button";
import { findCurrencySymbolByCode } from "lib/country";
import { thousandFormatter } from "lib/number";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logoutUser } from "state/slices/account";
import { logoutUserData } from "state/slices/users";
import { cn } from "utilities/cn";

const TopBar = ({ showSideBar, toggleSideBar, pageHasSideBar, pageTitle }) => {
  const dispatch = useDispatch();

  const location = useSelector((state) => state.location);

  const history = useHistory();

  const { user, wallets } = useSelector((state) => state.users.data);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(logoutUserData());
  };

  const eventWallet = wallets?.find(
    (wallet) => wallet.accountType === WALLET_ACCOUNT_TYPES.Events
  );

  return (
    <div className="tw-py-4 tw-pt-3 tw-tracking-wider tw-flex tw-justify-between tw-items-center">
      <div className="tw-flex tw-gap-4 tw-items-center">
        {pageHasSideBar ? (
          <Hamburger showSideBar={showSideBar} toggleSideBar={toggleSideBar} />
        ) : (
          <Link to="/">
            <Logo className="tw-w-32" />
          </Link>
        )}

        <span
          className={cn(
            "tw-uppercase",
            !pageHasSideBar && "tw-hidden sm:tw-inline"
          )}
        >
          {pageTitle}
        </span>
      </div>

      <div className="tw-flex tw-gap-1.5 md:tw-gap-3 tw-items-center">
        <div className="tw-hidden md:tw-flex tw-px-3 tw-py-2 tw-bg-white tw-rounded-md tw-items-center tw-justify-center tw-h-full tw-border-2 tw-border-white">
          <SearchIcon className="tw-w-6 tw-h-6" />
        </div>
        <div className="tw-px-2 md:tw-px-3 tw-py-1 tw-bg-white tw-rounded-md tw-relative tw-flex tw-items-center tw-justify-center tw-h-full tw-border-2 tw-border-white">
          <NotificationImportantIcon className="tw-w-8 tw-h-8" />
          {/* <div className="tw-rounded-full tw-bg-primary-500 tw-absolute tw-top-0 tw-right-2 tw-text-[0.6rem] tw-leading-none tw-text-white tw-p-1 tw-px-1">
            12
          </div> */}
        </div>
        {/* <Button
          className="tw-rounded-md tw-border tw-border-primary-500 tw-h-full tw-py-0 tw-px-0 tw-m-0 tw-z-10 tw-hidden md:tw-flex"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="tw-bg-gradient-to-r tw-from-[#EB3349] tw-to-[#F45C43] tw-flex tw-items-center tw-gap-2 tw-h-full tw-text-white tw-px-2 tw-py-2 tw-rounded-l-[0.3rem] tw-border-none">
            <AccountBalanceWalletIcon />
            <div className="tw-flex tw-flex-col tw-leading-none tw-text-left tw-gap-1">
              <span className="tw-text-[0.6rem] tw-font-light">
                Account Wallet
              </span>
              <span className="tw-text-[0.8rem]">₦&nbsp;0.00</span>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-min-w-[20px] tw-p-0 tw-m-0 tw--ml-2 tw-px-1 tw-h-full">
            <OutboundIcon />
          </div>
        </Button> */}
        {eventWallet && user.country === "Nigeria" && (
          <Button
            className="tw-rounded-md tw-border tw-border-secondary-800 tw-h-full tw-py-0 tw-px-0 tw-m-0 tw-hidden md:tw-flex"
            style={{ margin: 0, padding: 0 }}
            onClick={() => history.push("/dashboard/business-wallet")}
          >
            <div className="tw-bg-gradient-to-r tw-from-[#536976]  tw-to-[#292E49] tw-flex tw-items-center tw-gap-2 tw-h-full tw-text-white tw-px-2 tw-py-2 tw-rounded-l-[0.3rem] tw-border-none">
              <AccountBalanceWalletIcon />
              <div className="tw-flex tw-flex-col tw-leading-none tw-text-left tw-gap-1">
                <span className="tw-text-[0.6rem] tw-font-light">
                  Business Wallet
                </span>
                <span className="tw-text-[0.8rem]">
                  {findCurrencySymbolByCode(eventWallet.currency)}
                  {thousandFormatter(eventWallet.balance)}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-min-w-[20px] tw-p-0 tw-m-0 tw--ml-2 tw-px-1 tw-h-full">
              <OutboundIcon />
            </div>
          </Button>
        )}

        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "white",
              color: "black",
              border: "2px solid white",
              borderColor: "white",
              padding: "3px",
              margin: 0,
            }}
            id=""
            className="tw-text-gray-900 tw-max-w-[100px] md:tw-max-w-[150px] lg:tw-max-w-[200px]"
          >
            <div className="tw-flex tw-gap-2 tw-items-center tw-px-2 md:tw-px-1 md:tw-py-1">
              <div className="tw-relative">
                {user?.pictureUrl ? (
                  <div className="tw-max-w-[2rem] tw-max-h-[2rem] tw-rounded-full tw-overflow-hidden">
                    <img
                      src={user?.pictureUrl}
                      className="tw-w-full tw-h-full tw-rounded-full"
                    />
                  </div>
                ) : (
                  <UserIcon className="tw-w-6 tw-h-6" />
                )}
                <div
                  className={cn(
                    "tw-absolute tw-bottom-0 tw-right-0 tw-bg-white tw-p-.5 tw-rounded-full",
                    user?.pictureUrl && "p-0"
                  )}
                >
                  <EllipseIcon className="tw-text-green-500 tw-w-3 tw-h-3" />
                </div>
              </div>
              <div className="tw-truncate tw-hidden md:tw-inline">
                {user?.firstName}
              </div>
              <div>
                <ChevronRightIcon className="tw-rotate-90 tw-w-3 tw-h-3 tw-text-gray-700" />
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            id="account-dropdown-menu"
            className="tw-shadow-lg !tw-rounded-lg"
          >
            <Dropdown.Item className="account-dropdown-menu-item">
              <Link to="/dashboard">
                <div className="tw-flex tw-gap-3 tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-text-black">
                  <DashboardIcon className="tw-h-6 tw-w-6" /> Dashboard
                </div>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item className="account-dropdown-menu-item">
              <Link to="/dashboard/settings">
                <div className="tw-flex tw-gap-3 tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-text-black">
                  <SettingsIcon className="tw-h-6 tw-w-6" /> Account Settings
                </div>
              </Link>
            </Dropdown.Item>
            {/* <Dropdown.Item className="account-dropdown-menu-item">
              <div className="tw-flex tw-gap-3 tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100">
                <MultiUserIcon className="tw-h-6 tw-w-6" /> Multiuser Access
              </div>
            </Dropdown.Item> */}
            <Dropdown.Item
              onClick={handleLogout}
              className="account-dropdown-menu-item"
            >
              <div className="tw-flex tw-gap-3 tw-items-center tw-bg-primary-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-b-lg">
                <PowerIcon className="tw-h-6 tw-w-6" /> Logout
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "transparent",
              border: "2px solid white",
              borderColor: "transparent",
              padding: "3px",
              margin: 0,
              color: "white",
            }}
            id="dropdown-basic"
            className="tw-text-gray-900 tw-max-w-[100px] tw-h-full md:tw-max-w-[150px] lg:tw-max-w-[250px] tw-bg-primary-800"
          >
            <div
              className="tw-flex tw-gap-2 tw-items-center tw-px-3 md:!tw-px-8 tw-h-full tw-py-2 md:tw-py-4 tw-rounded-md"
              style={{
                backgroundImage: "linear-gradient(to right, #AF3C3F , #941C1F)",
              }}
            >
              <div className="tw-flex tw-items-center tw-gap-2 md:tw-gap-4">
                <div className="tw-hidden md:tw-inline">
                  Create Event / Sell Ticket{" "}
                </div>
                <div className="md:tw-hidden">
                  <PlusIcon />
                </div>
                <ChevronRightIcon className="tw-rotate-90 tw-w-3 tw-h-3 tw-text-white" />
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            id="account-dropdown-menu"
            className="tw-shadow-lg !tw-rounded-lg !tw-py-2 !tw-tracking-tight !tw-text-md"
          >
            <Dropdown.Item className="account-dropdown-menu-item">
              <Link to="/dashboard/create">
                <div className="tw-flex tw-gap-3 tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-text-black">
                  <img
                    src={CreateEventIllustration}
                    className="tw-h-6 tw-w-6"
                  />{" "}
                  Create Event / Sell Ticket
                </div>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item className="account-dropdown-menu-item">
              <Link to="/dashboard/resell-ticket">
                <div className="tw-flex tw-gap-3 tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 tw-text-black">
                  <img
                    src={ResellTicketIllustration}
                    className="tw-h-6 tw-w-6"
                  />{" "}
                  Resell Ticket
                </div>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Link to="/dashboard/create">
          <Button className="tw-flex tw-max-w-[15px] md:tw-max-w-full tw-px-2 lg:tw-px-10 lg:tw-py-5 tw-border-none tw-bg-gradient-to-r tw-from-[#AF3C3F] tw-to-[#941C1F] tw-text-white tw-py-5 tw-transition-all tw-h-100">
            <span className="tw-sr-only lg:tw-not-sr-only">Create Event</span>
            <span className="lg:tw-hidden">
              <PlusIcon />
            </span>
          </Button>
        </Link> */}
      </div>
    </div>
  );
};

export default TopBar;
