import { FUELSTATIONPLAINCONTAINER } from "constant";
import PropTypes from "prop-types";
import React from "react";

const PlainFuelStationDashboardLayout = (props) => {
  return (
    <div>
      <div
        className={`${FUELSTATIONPLAINCONTAINER} ${
          props.default !== true ? "tw-bg-white" : ""
        }`}
      >
        <div>
          <div className="tw-items-center tw-flex tw-justify-between">
            <h3 className="tw-font-semibold">{props.header}</h3>
            <div>{props.headerElement}</div>
          </div>
          <p>{props.description}</p>
        </div>
        <div className="tw-my-5">{props.children}</div>
      </div>
    </div>
  );
};

export default PlainFuelStationDashboardLayout;

PlainFuelStationDashboardLayout.PropTypes = {
  children: PropTypes.element,
  header: PropTypes.string,
  topElement: PropTypes.element,
  description: PropTypes.string,
  default: PropTypes.bool,
};
