import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import { User } from "services/network";
import { User as UserV2 } from "services/network-v2";
import { fetchFuelBuyerCustomerDashboard } from "services/network-v2/fuel";

const initialState = {
  userLoading: false,
  userError: null,
  data: {},
};

export const requestVerificationEmailRequest = createAsyncThunk(
  "users/resendVerificationEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.fetchAllUsers();
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllUsersRequest = createAsyncThunk(
  "users/fetchAll",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.fetchAllUsers();
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserRequest = createAsyncThunk(
  "users/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.createUser(payload);
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserByIdRequest = createAsyncThunk(
  "users/fetchById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await User.fetchUser(id);
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserRequest = createAsyncThunk(
  "users/update",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await User.updateUser(id, data);
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const enableUserRequest = createAsyncThunk(
  "users/enable",
  async (id, { rejectWithValue }) => {
    try {
      const response = await User.enableUser(id);
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const disableUserRequest = createAsyncThunk(
  "users/disable",
  async (id, { rejectWithValue }) => {
    try {
      const response = await User.disableUser(id);
      return response.data.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserProfileRequest = createAsyncThunk(
  "users/userProfile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.getProfile();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserProfileRequest = createAsyncThunk(
  "users/updateUserProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserV2.updateProfile(data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const closeAccountRequest = createAsyncThunk(
  "users/updateUserProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserV2.closeAccount(data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createShippingAddressRequest = createAsyncThunk(
  "account/createShippingAddress",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.createShippingAddress(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateShippingAddressRequest = createAsyncThunk(
  "account/updateShippingAddress",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.updateShippingAddress(
        payload.id,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteShippingAddressRequest = createAsyncThunk(
  "account/deleteShippingAddress",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.deleteShippingAddress(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAddressRequest = createAsyncThunk(
  "users/deleteAddress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await User.deleteAddress(data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);
export const addAddressRequest = createAsyncThunk(
  "users/updateUserProfile",
  async ({ formPayload, addy }, { rejectWithValue }) => {
    try {
      const response = await User.addAddress(formPayload, addy);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const editAddressRequest = createAsyncThunk(
  "users/updateUserProfile",
  async ({ formPayload, addyType }, { rejectWithValue }) => {
    try {
      const response = await User.editAddress(formPayload, addyType);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadPictureRequest = createAsyncThunk(
  "users/updateUserProfile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.uploadPicture(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserWalletsRequest = createAsyncThunk(
  "users/fetchUserWallets",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.fetchWallets();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserBankAccountsRequest = createAsyncThunk(
  "users/fetchUserBankAccounts",
  async (id, { rejectWithValue }) => {
    try {
      const response = await UserV2.fetchBankAcounts(id);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUserBankAccountRequest = createAsyncThunk(
  "users/createUserBankAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.createBankAccount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUserBankAccountRequest = createAsyncThunk(
  "users/updateUserBankAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await UserV2.updateBankAccount(payload.id, payload.data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const defaultAddyRequest = createAsyncThunk(
  "users/defaultAddy",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.defaultAddy(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const contactUsRequest = createAsyncThunk(
  "users/contactUs",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.contactUs(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWalletTransactionsRequest = createAsyncThunk(
  "users/getWalletTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getWalletTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVendorWalletBalanceRequest = createAsyncThunk(
  "users/getVendorWalletBalance",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getVendorWalletBalance(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWalletInfoRequest = createAsyncThunk(
  "users/getWalletInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getWalletInfo(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBusinessWalletTransactionsRequest = createAsyncThunk(
  "users/getBusinessWalletTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getBusinessWalletTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAvailableFeeRequest = createAsyncThunk(
  "user/getAvailableFee",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getAvailableFee(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getVendorBankRequest = createAsyncThunk(
  "user/getVendorBank",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getVendorBank(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addVendorBankRequest = createAsyncThunk(
  "user/addVendorBank",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.addVendorBank(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const requestPayoutRequest = createAsyncThunk(
  "user/requestPayout",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.requestPayout(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const activateWalletRequest = createAsyncThunk(
  "users/activateWallet",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.activateWallet(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const unlinkBusinessAccountRequest = createAsyncThunk(
  "users/unlinkBusinessAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.unlinkBusinessAccount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const initiateWalletTransactionRequest = createAsyncThunk(
  "users/initiateWalletTransaction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.initiateWalletTransaction(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyTransactionRequest = createAsyncThunk(
  "users/verifyTransaction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.verifyTransaction(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyCardStoreTransactionRequest = createAsyncThunk(
  "users/verifyCardStoreTransaction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.verifyCardStoreTransaction(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePinOneRequest = createAsyncThunk(
  "users/changePinOne",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.changePinOne(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePinTwoRequest = createAsyncThunk(
  "users/changePinTwo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.changePinTwo(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);
export const getChangePinRequest = createAsyncThunk(
  "users/getChangePin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getChangePin(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWalletTransactionPDFRequest = createAsyncThunk(
  "users/getWalletTransactionPDF",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.getWalletTransactionPDF(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logoutUserData: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.data = initialState.data;
    },
  },
  extraReducers: {
    [getUserProfileRequest.fulfilled]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      const token = localStorage.getItem("token");
      state.data = { user: action.payload.data, token };
    },
    [updateUserProfileRequest.fulfilled]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.data = { ...current(state.data), user: action.payload.data };
    },
    [createShippingAddressRequest.fulfilled]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.data = { ...current(state.data), user: action.payload.data };
    },
    [deleteShippingAddressRequest.fulfilled]: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.data = { ...current(state.data), user: action.payload.data };
    },
    [getWalletTransactionPDFRequest.fulfilled]: (state, action) => {
      state.data = {
        ...state.data,
        allTransaction: action.payload.data,
      };
    },
    [fetchUserWalletsRequest.fulfilled]: (state, action) => {
      state.data.wallets = action.payload.data;
    },
  },
});

const initialFuelCustomer = {
  userLoading: false,
  userError: null,
  selectedFuelStation: {},
  data: {},
};

//#region fuel station
const fuelCustomerSlice = createSlice({
  name: "fuelCustomer",
  initialState: initialFuelCustomer,
  reducers: {
    logoutFuelCustomerData: (state) => {
      localStorage.removeItem("fuelCustomerToken");
      localStorage.removeItem("fuelCustomer");
      state.data = initialFuelCustomer.data;
    },
    updateFuelCustomerDataAction: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [fetchFuelBuyerCustomerDashboard.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

const fuelVendorSlice = createSlice({
  name: "fuelVendor",
  initialState: initialFuelCustomer,
  reducers: {
    logoutVendors: (state) => {
      localStorage.removeItem("fuelVendorsToken");
      state.data = initialFuelCustomer.data;
    },
    updateFuelVendorData: (state, action) => {
      state.data = action.payload;
    },
    updateSelectedFuelStation: (state, action) => {
      state.selectedFuelStation = action.payload;
    },
  },
  extraReducers: {
    [fetchFuelBuyerCustomerDashboard.fulfilled]: (state, action) => {
      state.data = action.payload.data;
    },
  },
});

export const { logoutUserData } = usersSlice.actions;
export const { logoutFuelCustomerData, updateFuelCustomerDataAction } =
  fuelCustomerSlice.actions;
export const {
  logoutVendors,
  updateFuelVendorData,
  updateSelectedFuelStation,
} = fuelVendorSlice.actions;
export const fuelVendorsReducer = fuelVendorSlice.reducer;
export const fuelCustomerReducer = fuelCustomerSlice.reducer;
export default usersSlice.reducer;
