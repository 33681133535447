import TopBar from "components/pages/authenticated-new/components/TopBar";
import MainHeader from "components/shared/partials/MainHeader";
import React from "react";

const UnAuthencicatedDashboard = ({ children }) => {
  return (
    <div className="tw-bg-gray-50 tw-h-screen tw-full">
      <MainHeader />
      <div
        className="tw-w-full tw-text-center  tw-mx-auto tw-my-14 tw-container
           tw-max-w-sm"
      >
        {children}
      </div>
    </div>
  );
};

export default UnAuthencicatedDashboard;
