import SocialAuth from "../Login/SocialAuth";
import { validationSchema, initialValues, ACCOUNT_TYPES } from "./constants";
import "./styles.scss";
import Button from "components/shared/forms/Button";
import TextInput from "components/shared/forms/TextInput";
import { ErrorMessage, Field, Form, Formik } from "formik";
import produce from "immer";
import { convertCountryToOptions, countries } from "lib/country";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useGoogleLogin } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { signUpRequest } from "state/slices/account";
import { fetchAllEventCategoriesRequest } from "state/slices/events";
import {
  convertArrayToSelectOptions,
  getAPIErrorsForFormik,
  getOneLinerErrorMessage,
} from "utilities";

const SignUp = ({ successAction }) => {
  const dispatch = useDispatch();

  const [isAgreed, setIsAgreed] = useState(false);
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  const location = useSelector((state) => state.location);

  const savedLocation = useSelector((state) => state.location);

  useLayoutEffect(() => {
    document.title = "Triple7Events - Sign Up";
  }, []);

  const [state, setState] = useState({
    isSignUpLoading: false,
  });

  const handleSignUp = async (formValues, formikHelpers) => {
    const formPayload = {
      ...formValues,
      country: location?.country || "",
    };

    setState(produce((draft) => void (draft.isSignUpLoading = true)));

    const resultAction = await dispatch(signUpRequest(formPayload));

    if (signUpRequest.fulfilled.match(resultAction)) {
      localStorage.setItem(
        "token",
        resultAction.payload?.data?.tokens?.accessToken
      );
      successAction?.();
      toast.success("Successfully created account");
      formikHelpers.resetForm();
      setState(produce((draft) => void (draft.isSignUpLoading = false)));
    } else {
      formikHelpers.setErrors(getAPIErrorsForFormik(resultAction.payload));
      setState(produce((draft) => void (draft.isSignUpLoading = false)));

      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.payload?.message ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <Fragment>
      <div className="mx-auto registerFormBigDiv col container-fluid">
        <Formik
          initialValues={{
            ...initialValues,
            country: {
              label: savedLocation?.country,
              value: savedLocation?.country,
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSignUp}
        >
          {({ handleSubmit, isValid, values, setFieldValue, errors }) => {
            return (
              <Form
                className="pt-4 mx-auto registerSec"
                onSubmit={handleSubmit}
              >
                <div className="regNameSec row">
                  <div className="mx-auto fNameSec col-12 col-md-12 col-lg-6">
                    <label htmlFor="" className="tw-text-lg">
                      First Name
                    </label>
                    <div className="registerFormDiv">
                      <Field
                        component={TextInput}
                        className="formRegCtrl"
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="mx-auto fNameSec col-12 col-md-12 col-lg-6">
                    <label htmlFor="" className="tw-text-lg">
                      Last Name
                    </label>
                    <div className="registerFormDiv">
                      <Field
                        component={TextInput}
                        className="formRegCtrl"
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="regNameSec row">
                  <div className="mx-auto fNameSec col-12 col-md-12 col-lg-6">
                    <label htmlFor="" className="tw-text-lg">
                      Email
                    </label>
                    <div className="registerFormDiv">
                      <Field
                        component={TextInput}
                        className="formRegCtrl"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="mx-auto fNameSec col-12 col-md-12 col-lg-6">
                    <label htmlFor="small-radius-switch" className="tw-text-lg">
                      Account Type
                    </label>
                    <div
                      className="registerFormDiv tw-px-0"
                      style={{ padding: 0 }}
                    >
                      {/* <Field
                        component={Select}
                        options={convertArrayToSelectOptions(
                          Object.keys(ACCOUNT_TYPES)
                        )}
                        className="formRegCtrls"
                        type="text"
                        name="accountType"
                        id="accountType"
                        placeholder="Account Type"
                        style={{ border: 0 }}
                      /> */}
                      <select
                        name="accountType"
                        className="formRegCtrls"
                        onChange={(e) =>
                          setFieldValue("accountType", e.target.value)
                        }
                      >
                        <option value="">Account Type</option>
                        {Object.keys(ACCOUNT_TYPES).map((type, Idx) => (
                          <option
                            value={ACCOUNT_TYPES[type]}
                            className="tw-capitalize tw-text-lg"
                            key={Idx}
                          >
                            {ACCOUNT_TYPES[type]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {values.accountType === ACCOUNT_TYPES.organization && (
                  <div className="regNameSec row">
                    <div className="mx-auto fNameSec col-12 col-md-12 col-lg-12">
                      <label htmlFor="" className="tw-text-lg">
                        Organization Name
                      </label>
                      <div className="registerFormDiv">
                        <Field
                          component={TextInput}
                          className="formRegCtrl"
                          type="text"
                          name="organizationName"
                          id="organizationName"
                          placeholder="Organization Name"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="regNameSec row">
                  <div className="mx-auto fNameSec col-12 col-md-12 col-lg-6">
                    <label htmlFor="">Password</label>
                    <div className="registerFormDiv">
                      <Field
                        component={TextInput}
                        className="formRegCtrl"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div className="mx-auto fNameSec col-12 col-md-12 col-lg-6">
                    <label htmlFor="small-radius-switch">
                      Confirm Password
                    </label>
                    <div className="registerFormDiv">
                      <Field
                        component={TextInput}
                        className="formRegCtrl"
                        type="password"
                        name="confirmPassword"
                        id="retypePassword"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="mt-3 text-xs d-none errPass font-weight-medium text-accent">
                    Password must be at least 6 characters in length and must
                    contain at least 1 lowercase letter, 1 uppercase letter and
                    1 number
                  </p>
                )}
                <div className="regTAC">
                  <input
                    type="checkbox"
                    checked={isAgreed}
                    onChange={handleCheckboxChange}
                    className="mr-3 tw-cursor-pointer"
                    name=""
                    id=""
                  />
                  <span className="tw-text-lg">
                    I agree to the{" "}
                    <a href="/terms" target="_blank">
                      Terms & Conditions
                    </a>{" "}
                    of Triple7Events.
                  </span>
                </div>
                <div className="buttonRegDiv">
                  <Button
                    type="submit"
                    className="btnreg"
                    title="Sign Up"
                    loadingTitle="Signing up..."
                    loading={state.isSignUpLoading}
                    disabled={state.isSignUpLoading || !isValid || !isAgreed}
                  />
                </div>
                <div className="orRO !tw-max-w-[500px] tw-mx-auto">
                  <hr className="" />{" "}
                  <small className="tw-text-lg tw-px-8">Continue with</small>
                  <hr className="" />
                </div>
                <SocialAuth className="otherRegisterOptions" />
                <small className="RegisterCR">
                  © {new Date().getFullYear()} Triple7 Events.
                </small>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );
};

export default withRouter(SignUp);
