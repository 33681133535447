import Loader from "../Loader";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";
import { Spinner } from "react-bootstrap";
import { cn } from "utilities/cn";

const buttonVariants = cva(
  "tw-inline-flex tw-py-[1.8rem] tw-px-[2rem] tw-items-center tw-justify-center tw-rounded-lg tw-text-sm tw-font-medium tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring disabled:tw-pointer-events-none disabled:tw-opacity-50 hover:tw-opacity-90 hover:tw-scale-[1.02] active:tw-scale-95 tw-transition-all tw-duration-500 tw-gap-2",
  {
    variants: {
      variant: {
        default:
          "tw-bg-primary tw-text-primary-foreground tw-shadow hover:tw-bg-primary/90",
        destructive:
          "tw-bg-destructive tw-text-destructive-foreground tw-shadow-sm hover:tw-bg-destructive/90",
        primary: "tw-bg-primary-800 tw-text-white",
        outline:
          "tw-border tw-border-1 tw-border tw-bg-transparent tw-shadow-sm hover:tw-bg-accent hover:tw-text-accent-foreground",
        secondary:
          "tw-bg-secondary tw-text-secondary-foreground tw-shadow-sm hover:tw-bg-secondary/80",
        ghost: "hover:tw-bg-accent hover:tw-text-accent-foreground",
        link: "tw-text-primary tw-underline-offset-4 hover:tw-underline",
      },
      size: {
        default: "tw-h-12 tw-px-8 tw-py-2",
        sm: "tw-h-8 tw-rounded-md tw-px-3 tw-text-xs",
        lg: "tw-h-10 tw-rounded-md tw-px-8",
        icon: "tw-h-9 tw-w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  (
    { className, children, variant, size, asChild = false, loading, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <>
        <Comp
          className={cn(buttonVariants({ className, variant, size }))}
          ref={ref}
          {...props}
        >
          {loading && (
            <Spinner size="sm" animation="border" role="status">
              <span className="tw-sr-only">Loading...</span>
            </Spinner>
          )}
          {children}
        </Comp>
      </>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
